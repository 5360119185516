<template>
    <div class="table-responsive align-items-center mb-0 mt-3" style = "margin: 0px 20px;">
        <table class="table table-responsive table-striped table-hover align-items-center">
            <thead class="bg-dark">
            <tr>
                <th class="text-uppercase text-white text-sm font-weight-bolder opacity-7 px-2 mx-0">Name</th>
                <th class="text-uppercase text-white text-sm font-weight-bolder opacity-7 px-2 mx-0">E-mail</th>
                <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">First User</th>
                <th class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7">Manager</th>
            </tr>
            </thead>

            <tbody>
                <tr v-if="data_users.users != null && data_users.users!=0" v-for="user in data_users.users" :key="user.id">
                    <td><span class="file"> {{ user.first_name }} {{ user.last_name }}</span></td>

                    <td width="30%;"> {{ user.email }}</td>

                    <td  width="10%;" class = "text-center">
                        <span v-if="user.is_firs_user_client == 'true'" class="text-xs font-weight-bold" style="color: rgb(58, 57, 57)"  >
                        <i class="bi bi-check-circle text-success" style="font-size:1rem;"></i>
                        </span>
                        <span v-else class="text-xs font-weight-bold" style="color: rgb(58, 57, 57)"  >
                        <i class="bi bi-x-circle text-danger" style="font-size:1rem;"></i>
                        </span>
                    </td>

                    <td class="text-center">
                        <MaterialButton color="info" v-if="this.login_user_email == user.email" v-on:click="set_values_user(user.name, user.sobrenome, user.email)" class = "m-1" data-bs-toggle="modal" data-bs-target="#altername" >Change name</MaterialButton>
                        <MaterialButton color="info" v-if="this.login_user_email == user.email" v-on:click="set_values_password(user.email)" class = "m-1"  data-bs-toggle="modal" data-bs-target="#alterpassword">Change password</MaterialButton>
                    </td>
                </tr>
                <tr v-else>
                    <td colspan="4" class="text-center text-muted text-sm">No users found.</td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Modal  new user -->
    <div class="modal fade" id="createnewuser" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="createnewuserlLabel">Create New User</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div class="card bg-light border" id="card-newuser">
                    <div class="card-body py-2 px-2">                        
                        <div class="d-flex flex-column gap-2">
                            <label for="floatingName" class="form-label py-0 my-0 text-dark font-weight-bold">Name</label>
                            <div class="input-group input-group-outline">                                
                                <input type="text" class="form-control form-control-default" v-model="first_name" id="floatingName" placeholder="First name">
                            </div>
                        </div>

                        <div class="mt-3 d-flex flex-column gap-2">
                            <label for="floatingSurname" class="form-label py-0 my-0 text-dark font-weight-bold">Surname</label>
                            <div class="input-group input-group-outline">                                
                                <input type="text" class="form-control form-control-default" v-model="last_name" id="floatingSurname" placeholder="Surname">
                            </div>
                        </div>

                        <div class="mt-3 d-flex flex-column gap-2">
                            <label for="floatingEmail" class="form-label py-0 my-0 text-dark font-weight-bold">E-mail</label>
                            <div class="input-group input-group-outline">                                
                                <input type="email" class="form-control form-control-default" v-model="email" id="floatingEmail" placeholder="name@example.com">
                            </div>
                        </div>

                        <div class="mt-3 d-flex flex-column gap-2">
                          <label for="floatingPassword" class="form-label py-0 my-0 text-dark font-weight-bold">Password</label>
                          <div class="input-group input-group-outline">
                            <input type="password" class="form-control form-control-default" v-model="password" id="floatingPassword" placeholder="Password">
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success" @click="Create_user">Create</button>
            </div>
            </div>
        </div>
    </div>

    <!-- modal name -->
    <div class="modal fade" id="altername" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="createnewuserlLabel">Change Name &nbsp;<span class="badge bg-info fs-8 text-lowercase">{{this.email_selected}}</span></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="card bg-light border" id="card-newuser">
                        <div class="card-body py-2 px-2">                        
                            <div class="d-flex flex-column gap-2">
                                <label for="floatingEditName" class="form-label py-0 my-0 text-dark font-weight-bold">Name</label>
                                <div class="input-group input-group-outline">                                
                                    <input type="text" v-model="this.new_first_name" class="form-control form-control-default" id="floatingEditName" placeholder="First name">
                                </div>
                            </div>

                            <div class="mt-3 d-flex flex-column gap-2">
                                <label for="floatingEditSurname" class="form-label py-0 my-0 text-dark font-weight-bold">Surname</label>
                                <div class="input-group input-group-outline">                                
                                    <input type="text" v-model="this.new_last_name" class="form-control form-control-default" id="floatingEditSurname" placeholder="Surname">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="update_name_user" >Save</button>
                </div>
            </div>
        </div>
    </div>

    <!--update password -->
    <div class="modal fade" id="alterpassword" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="createnewuserlLabel">Change Password &nbsp;<span class="badge bg-info fs-8 text-lowercase">{{this.email_selected}}</span></h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="card bg-light border" id="card-newuser">
                        <div class="card-body py-2 px-2">                            
                            <div class="d-flex flex-column gap-2">
                                <label for="floatingOldPass" class="form-label py-0 my-0 text-dark font-weight-bold">Old Password</label>
                                <div class="input-group input-group-outline">                                    
                                    <input type="password" v-model="this.old_password" class="form-control form-control-default" id="floatingOldPass" placeholder="name@example.com">
                                </div>
                            </div>
    
                            <div class="mt-3 d-flex flex-column gap-2">
                                <label for="floatingEditPassword" class="form-label py-0 my-0 text-dark font-weight-bold">New Password</label>
                                <div class="input-group input-group-outline">                                    
                                    <input type="password" v-model="this.new_password" class="form-control form-control-default" id="floatingEditPassword" placeholder="Password">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-success" @click="update_password_user" >Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MaterialButton from "@/components/MaterialButton.vue";
import {http} from "@/http/index";

    export default {
    name: "UserTable",

    components : { 
        MaterialButton
    },
    
    beforeMount() {
        this.actualy_user_token = localStorage.getItem("token")
        this.login_user_email = localStorage.getItem("email")
    },

    methods : {
        
        data() {
            return {
            actualy_user_token: null,
            login_user_email: null,
            email_selected: null,
            first_name: null,
            last_name: null,
            new_first_name: null,
            new_last_name: null,
            old_password: null,
            new_password: null,
            data_users: [],
            loading: true
            };
        },

        update_name_user: function() {

            

            var payload={
                token: localStorage.getItem("token"),    
                new_first_name: this.new_first_name,
                new_last_name: this.new_last_name,  
                email: this.email_selected  
                    
            }

            http.post('/api/v1/dashboard/alter-name/', payload)
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
            })
        },

        update_password_user: function() {

            var payload={
                token: this.actualy_user_token,
                old_password: this.old_password,
                new_password: this.new_password,
                email: this.email_selected
            }

            http.post('/api/v1/dashboard/alter-password/', payload)
            .then(response => {
                window.location.reload();
            })
            .catch(error => {
            })
        },

        set_values_user: function (selected_name, selected_sobrenome, email_selected) {
            this.name = selected_name;
            this.email_selected = email_selected;
            this.sobrenome = selected_sobrenome;
            },

            set_values_password: function (email_selected) {
            this.email_selected = email_selected;    
        }, 


        Create_user: function() {
            
            var payload={
                token: localStorage.getItem("token"),
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                password: this.password
            }
            http.post('/api/v1/dashboard/new-user/', payload)
            .then(response => {
            window.location.reload();
            })
            .catch(error => {
            })
        }

    },

    props: {

        data_users: {
            type: Array,
            required: true
        }
  }
}
</script>

<style>
.file{
    font-weight: 800;
    color: #616161
}
</style>