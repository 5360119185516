import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import DashboardLys from "../views/DashboardLys.vue";
import ControUsers from "../views/ControUsers.vue";
import ListReports from "../views/Reports.vue";
import Projects from "../views/Projects.vue";
import PillContents from "../views/PillContents.vue";
import PillRegistrations from "../views/PillRegistrations.vue";
import PillAnswers from "../views/PillAnswers.vue";
import PushBatchesInfo from "../views/PushBatchInfo.vue";
import PushBatchView from "../views/PushBatchView.vue";
import DashboardExample from "../views/DashboardLys.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import RecoveringPassword from "../views/RecoveringPassword.vue";

import RegistrationPage from "../views/RegistrationPage.vue";
import BatchParticipants from "../views/BatchParticipants.vue";
import BatchParticipantsEnrollments from "../views/BatchParticipantsEnrollments.vue";
import BatchParticipantsListByUser from "../views/BatchParticipantsListByUser.vue";
import BatchParticipantsDetails from "../views/BatchParticipantsDetails.vue";


import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Depositions from "../views/Depositions.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import SignInVue from "../views/SignIn.vue";
import { http } from "../http";

const routes = [
  {
    path: "/",
    name: "/",
    component: DashboardLys,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    /* component: Dashboard, */
    component: DashboardLys
  },
  {
    path: "/depositions",
    name: "Depositions",
    /* component: Dashboard, */
    component: Depositions
  },
  {
    path: "/list_users",
    name: "ListUsers",
    component: ControUsers,
  },

  {
    path: "/push_batches",
    name: "PushBatchesInfo",
    component: PushBatchesInfo,
  },

  {
    path: "/push_batch_view/:id",
    name: "PushBatchView",
    component: PushBatchView,
  },

  {
    path: "/pills",
    name: "Pills",
    component: PillContents,
  },  

  {
    path: "/pill-registrations/:id",
    name: "Pill Registrations",
    component: PillRegistrations,
  },  

  {
    path: "/pill-answers/:id",
    name: "Pill Answers",
    component: PillAnswers,
  },

  {
    path: "/participants",
    name: "BatchParticipants",
    component: BatchParticipants,
  },

  {
    path: "/participants/:id",
    name: "BatchParticipantsID",
    component: BatchParticipants,
  },

  {
    path: "/participants-enrollments/:id/:course_id",
    name: "Batch Participants Enrollments",
    component: BatchParticipantsEnrollments,
  },

  {
    path: "/participants-pushbatch-byuser/:id",
    name: "List Push Batch By User",
    component: BatchParticipantsListByUser,
  },

  {
    path: "/participants-pushbatch-details/:id/:course_id",
    name: "Push Batch Details",
    component: BatchParticipantsDetails,
  },

  {
    path: "/registration-page/:id",
    name: "Registration Page",
    component: RegistrationPage,
  },

  {
    path: "/list_reports",
    name: "ListReports",
    component: ListReports,
  },

  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/dashboard-example",
    name: "Dashboard Example",
    component: DashboardExample,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/recovering-password/:token",
    name: "RecoveringPassword",
    component: RecoveringPassword,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach((to, from, next) => {
//   if (to.name === "SignIn") {
//     // next({ name: "Dashboard" });
//   } else {
//     next();
//   }

//   if (localStorage.getItem("token")) {
    
    
//     const payload = {
//       token: localStorage.getItem("token"),
//       mac_token: localStorage.getItem("mac_token"),
//     };
    
//     http.post("/api/v1/dashboard/confirm_token_client/", payload)
//       .then(response => {
//         var login = response.status === 200;
//         next();
        
//       })
//       .catch(error => {
//         localStorage.clear();
//         this.$router.push('/login')
//       });
//   }
//   else {
//     localStorage.clear();
//     this.$router.push('/login')
//   }


// });

export default router;
