<template>
    <div class="d-flex align-items-center mb-0" style="margin: 0px; padding: 0px 20px;">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th class="text-left px-0">Student Name</th>
                    <th class="text-center">Student Phone</th>
                    <th class="text-center">Course Name</th>
                    <th class="text-center">Lesson Name</th>
                    <th class="text-center">Text Depositions</th>
                    <th class="text-center">Score</th>
                    <th class="text-center">Created</th>
                </tr>
            </thead>
            
            <tbody>        
                <tr v-if="Array.isArray(returnAPI) && returnAPI.length > 0" v-for="rep in returnAPI" :key="rep.id">
                    <td class="text-left align-middle" wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;"><span class="file">{{rep.student_name}}</span></td>
                    <td class="text-center align-middle">{{rep.student_phone_number}}</td>
                    <td class="text-center align-middle">{{rep.course_name}}</td>
                    <td class="text-center align-middle">{{rep.lesson_name}}</td>
                    <td class="align-middle" wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">{{rep.text_deposition}}</td>
                    <td class="text-center align-middle">{{rep.score}}</td>
                    <td class="text-center align-middle">{{formateDate(rep.created)}}</td>
                </tr>
                <tr v-else>
                    <td class="text-center align-middle" colspan="7">
                        <span class="text-lg text-muted">No data found</span>
                    </td>
                </tr>
            </tbody>
        </table>    
    </div>
</template>

<script>

import MaterialButton from "@/components/MaterialButton.vue";

    export default {
    name: "ReportTable",

    components : {
        MaterialButton 
    },

    methods : {        
        download(file){
            window.open(file, '_blank');
        },

        formateDate(date){
            const dataObj = new Date(date);
            const year = dataObj.getUTCFullYear();
            const month = dataObj.getUTCMonth() + 1; 
            const day = dataObj.getUTCDate();
            const hour = dataObj.getUTCHours();
            const minutes = dataObj.getUTCMinutes();

            if (day < 10) {
                if (month < 10) {
                    return `0${day}/0${month}/${year} ${hour}:${minutes}`;
                } else {
                    return `0${day}/${month}/${year} ${hour}:${minutes}`;
                }
            } else if (month < 10) {
                return `${day}/0${month}/${year} ${hour}:${minutes}`;
            }

            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },

        outOfDate(date){
            const currentDate = new Date();
            const dataObj = new Date(date);

            // Calcula a diferença em milissegundos
            const differenceInMillis = currentDate - dataObj;

            // Converte a diferença de milissegundos para dias
            const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

            // Retorna false se a diferença for maior que 16 dias, caso contrário true
            return differenceInDays < 17;
        }
    },

    props: {

        returnAPI: {
            type: Array,
            required: true
        }
  }
}
</script>

<style>
.file{
    font-weight: 800;
    color: #616161
}

table {
    table-layout: fixed; /* Controla a largura das colunas para garantir a quebra */
    width: 100%;
}

td {
    word-wrap: break-word;
    word-break: break-word; /* Permite quebra de linha */
    white-space: normal; /* Impede a rolagem horizontal */
}

th, td {
    padding: 8px; /* Adiciona espaçamento */
    overflow-wrap: break-word; /* Garante a quebra em palavras longas */
}
</style>