<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'BatchParticipantsID', params: { id: project_id } }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
          <div
            class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
            <h6 class="col text-white text-capitalize ps-3">Participants - List Registration Imports</h6>
          </div>

          <div v-if="this.showAlert"
            :class="[
              'alert',
              this.responseStatusCode == 'success'
                ? 'alert-success'
                : this.responseStatusCode == 'error'
                ? 'alert-danger'
                : this.responseStatusCode == 'pending'
                ? 'alert-warning'
                : 'alert-info',
            ]"
            role="alert"
          >
            <span class="text-alert">{{ this.alertMessage }}</span>
          </div>

          <div class="col-lg-20 mb-md-0 mb-4">
            <div class="row">
              <div class="d-flex justify-content-end w-100">
                <div class="d-flex gap-2">
                  <button @click="CSVExampleDownload()" class="btn bg-gradient-info top-button" ><span class="bi bi-download"> Example</span></button>
                  <button @click="pushBatchForm()" class="btn bg-gradient-info top-button" ><span class="bi bi-cloud-arrow-up"> Import</span></button>
                </div>
              </div>
            </div>

            <div v-if="loading == false" class="row mt-4">
              <div v-if="this.showPushBatchForm" class="push-batch-form">
                <div class="d-flex justify-content-center w-100">
                  <div class="card w-50 border">
                    <div class="card-header pt-3 pb-0">
                      <h4>Import Participants in Batch</h4>
                    </div>

                    <div class="alert alert-warning d-flex align-items-center m-4" role="alert">
                      <i class="fs-2 text-alert m-3 bi bi-exclamation-triangle"></i>
                      <div class="text-alert">
                        <p>Download the example CSV by clicking on the 'EXAMPLE' button at the top of this page;</p>
                        <p>Fill in the data for import WITHOUT EDITING the column names;</p>
                        <p>
                          The column containing the participant number will be used to register the participant in the course.
                          <br>
                          So, make sure the number is correct and in the proper format;
                        </p>
                        <p>Select the file and click 'IMPORT'.</p>
                      </div>
                    </div>
  
                    <div class="card-body">
                      <div>
                        <label for="identifier" class="form-label">Identifier</label>
                        <div class="input-group input-group-outline">
                          <input id="identifier" type="text" class="form-control form-control-default" name="identifier" placeholder="Identifier">
                        </div>
                      </div>

                      <div class="mt-2">
                        <label for="file" class="form-label">CSV File</label>
                        <div class="input-group input-group-outline">
                          <input id="file" type="file" class="form-control" name="file" placeholder="File">
                        </div>
                      </div>

                      <div style="position: relative; margin-bottom: 10px; margin-top: 10px;">   
                        Request push sending:
                        <input style="position: absolute; margin-top: 7px; margin-left: 2px;" type="checkbox" id="checkbox" v-model="checked"/>
                      </div>

                      <div class="mx-auto d-flex justify-content-around mt-3">                          
                        <button @click="cancelPushBatch()" class="btn mb-0 bg-gradient-danger btn-lg w-40 top-button">Cancel</button>
                        <button @click="createPushBatch()" class="btn mb-0 bg-gradient-success btn-lg w-40 top-button" :disabled="this.isImporting">Import</button>
                      </div>
                    </div>                      
                  </div>
                </div>
              </div>
            </div>

            <table class="table table-striped table-hover align-items-center mb-0 mt-4">
              <thead class="bg-dark">
                <tr>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Identifier
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Created by
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Status
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Numbers Count
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Processed Numbers
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Created
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Modified
                  </th>
                  <th
                    class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                  >
                  Details
                  </th>
                </tr>
              </thead>
              
              <tbody v-if="pushBatches!=null && pushBatches!=0">
                <tr v-if="loading==false" v-for="pushBatch in pushBatches" :key="pushBatch.id">
                  <td >
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{pushBatch.identifier}}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.created_by_email}}</span
                    >
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span v-if="pushBatch.status == 'INITIAL'" class="badge badge-sm bg-gradient-success">
                      Registered
                    </span>
                    <span v-if="pushBatch.status == 'RUNNING'" class="badge badge-sm bg-gradient-primary">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'FINISHED'" class="badge badge-sm bg-gradient-success">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'FAILED'" class="badge badge-sm bg-gradient-danger">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'REGISTERING'" class="badge badge-sm bg-gradient-info">
                      {{pushBatch.status}}
                    </span>
                    <span v-if="pushBatch.status == 'WAITING'" class="badge badge-sm bg-gradient-warning">
                      {{pushBatch.status}}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.numbers_count}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{pushBatch.processed_numbers}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushBatch.created)}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold"
                      >{{formatDate(pushBatch.modified)}}</span
                    >
                  </td>
                  <td class="align-middle text-center">
                    <router-link 
                      title="Batch details"
                      class="btn bg-gradient-warning btn-rounded my-0 fs-6 m-1"
                      :to="{ name: 'Push Batch Details', params: { id: pushBatch.id, course_id: this.project_id } }"
                    >
                      <i class="bi bi-plus-circle"></i>
                    </router-link>
                  </td>
                </tr>

                <tr v-else>
                  <td colspan="9" class="text-center text-secondary">
                    <Loading message="Loading datas" :loading = "loading"/>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr v-if="loading==false">
                  <td colspan="9" class="text-center text-secondary">No registration imports found.</td>
                </tr>
                <tr v-else>
                  <td colspan="9" class="text-center text-secondary">
                    <Loading message="Loading datas" :loading = "loading"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, http_file } from "../http/index";
import lysLogo from "@/assets/img/icon-lys.png";
import MaterialButton from "@/components/MaterialButton.vue";
import Loading from "@/views/components/Loading.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";


export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    MiniStatisticsCard,
    Loading    
  },

  data() {
    return {
      loading: true,
      lysLogo,
      project_id: this.$route.params.id,      
      token_user: localStorage.getItem("token"),

      pushBatchIdentifier: "",
      pushBatchFile: "",
      checked: false,
      showAlert: false,
      showPushBatchForm: false,
      alertMessage: "",
      responseStatusCode: "",
      pushBatches : null,
    };
  },

  mounted() {
    this.getAllPushBatches()
  },  

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }      
    },

    createPushBatch() {
      if (document.getElementById('identifier').value === '' || document.getElementById('file').files.length === 0) {
          this.showAlert = true
          this.alertMessage = "Please fill in all fields."
          return
      }

      this.pushBatchIdentifier = document.getElementById('identifier').value
      this.pushBatchFile = document.getElementById('file').files[0]

      if (this.pushBatchFile.type !== 'text/csv') {
          this.showAlert = true
          this.alertMessage = "The file selected is not a CSV file."
          this.pushBatchFile = ""
          document.getElementById('file').value = ""
          return
      }

      let reader = new FileReader()
      reader.onload = (event) => {
          let csvContent = event.target.result
      }
      reader.readAsText(this.pushBatchFile)

      this.isImporting = true
      this.showAlert = true
      this.alertMessage = "Importing the Data. Please wait!"
      this.responseStatusCode = "pending"

      let formData = new FormData()
      formData.append('identifier', this.pushBatchIdentifier)
      formData.append('file', this.pushBatchFile)
      formData.append('course_id', this.project_id)
      formData.append('token', this.token_user)
      formData.append('email_for_push', this.checked)

      http_file.post('/api/v1/dashboard/create-push-batch/', formData)
      .then((response) => {
          this.showAlert = true
          this.alertMessage = response.data.detail
          this.responseStatusCode = "success"
          this.pushBatchIdentifier = ""
          this.pushBatchFile = ""
          document.getElementById('identifier').value = ""
          document.getElementById('file').value = ""

      })
      .catch((error) => {
          this.showAlert = true
          this.alertMessage = error.response.data.detail
          this.responseStatusCode = "error"
          this.pushBatchFile = ""
          document.getElementById('file').value = ""
      })
      .finally(() => {
          this.isImporting = false
          this.showPushBatchForm = false
          this.getAllPushBatches()
      })
    },

    pushBatchForm() {
      this.showPushBatchForm = !this.showPushBatchForm
      this.showAlert = false
      this.alertMessage = ""
    },

    cancelPushBatch() {
      this.showPushBatchForm = false
      this.showAlert = false
      this.alertMessage = ""
      this.pushBatchIdentifier = ""
      this.pushBatchFile = ""
      document.getElementById('identifier').value = ""
      document.getElementById('file').value = ""
    },

    getAllPushBatches() {
      let payload = {
          token: this.token_user,
          course_id: this.project_id
      }

      http.post('/api/v1/dashboard/list-push-batch-by-user/', payload)
      .then((response) => {
          this.pushBatches = response.data;
      })
      .catch((error) => {
      })
      .finally(() => {
          this.loading = false
      })
    },

    CSVExampleDownload() {
      let payload = {
          course_id: this.project_id
      }

      let listFields

      this.showAlert = true
      this.alertMessage = "Downloading the example CSV file. Please wait!"
      this.responseStatusCode = "pending"

      http.post('/api/v1/dashboard/list-fields-by-course/', payload)
      .then((response) => {
        listFields = response.data;
      })
      .catch((error) => {
          this.showAlert = true
          this.alertMessage = "An error occurred while downloading the example CSV file. Please try again."
          this.responseStatusCode = "error"
      })
      .finally(() => {
        this.downloadCSV(listFields.fields)
      })
    },

    downloadCSV(fields) {
      let exampleRow = []

      fields.forEach(field => {
        exampleRow.push(field + ' 01')
      })

      const allRows = [fields, exampleRow]
      const csvContent = "data:text/csv;charset=utf-8," + allRows.map(row => row.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement("a")

      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "example.csv")
      document.body.appendChild(link)
      link.click()

      this.showAlert = true
      this.alertMessage = "The example CSV file has been downloaded."
      this.responseStatusCode = "success"
    },
  }
}
</script>

<style scoped>
  .form-control[type=file]:not(:disabled):not([readonly]) {
    background-color: white;
    border: 1px solid #d2d6da;
    color: black;
  }

  .text-alert{
    color: #FFF;
  }

  .push-batch-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 15px;
  }
</style>