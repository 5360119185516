<template>
  <div class="container-fluid p-1 position-relative ">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'BatchParticipantsID', params: { id: project_id } }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12">
      <div class="card my-4">
        <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
          <div
            class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
            <h6 class="col text-white text-capitalize ps-3">Participants - Registration Page</h6>
          </div>
          
          <Loading message="Loading registration page" :loading = "loading"/>
          
          <div v-if="loading == false">
            <iframe :src="lp_url_from_course" width="100%" height="800px"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, url_from_app_user } from "../http/index";
import Loading from "@/views/components/Loading.vue";


export default {
  name: "RegistrationPage",

  components : {
    Loading    
  },  

  data() {
    return {
      loading: true,
      searchFilter: "",
      lp_url_from_course: null,
      project_id: this.$route.params.id,
      token_user: localStorage.getItem("token"),
    };
  },

  mounted() {
    this.getAllenrollments()
  },

  methods: {
    getAllenrollments(){
      let payload = {
        token_user: this.token_user,
        course_id: this.project_id,
        pagination: 1,
        filter: this.searchFilter
      }
      
      http.post('/api/v1/dashboard/list-enrollment-from-course-client/', payload)
      .then((response) => (this.data_api_enrollments_list = response.data))          
      .finally(() => {
        this.loading = false
        this.data_course = this.data_api_enrollments_list.information_course
        
        if (this.data_course.is_privete == true){
          this.lp_url_from_course = url_from_app_user + this.data_course.external_exercise_id + "/access/" + this.token_user;
        }else{
          this.lp_url_from_course = url_from_app_user + this.data_course.external_exercise_id;
        }
      });        
    },
  }
}
</script>

<style scoped>
  .top-button {
    margin: 0px 0px 10px;
  }
</style>