<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
            <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
              <h6 class="col text-white text-capitalize ps-3">Content Pills</h6>
            </div>

            <div
              v-if="this.showAlert"
              :class="[
                'alert',
                this.responseStatusCode == 'success'
                  ? 'alert-success'
                  : this.responseStatusCode == 'error'
                  ? 'alert-danger'
                  : this.responseStatusCode == 'pending'
                  ? 'alert-warning'
                  : 'alert-info',
              ]"
              role="alert"
            >
              <span class="text-alert">{{ this.alertMessage }}</span>
            </div>
          </div>

          <Loading message="Loading your pills" :loading = "loading"/>

          <div v-if="loading == false" class="col-lg-12 position-relative z-index-2">
            <div class="d-flex justify-content-evenly px-2">
              <div class="d-flex flex-column justify-content-start align-items-start col-lg-4 col-md-12 col-sm-12 gap-4">
                <div class="col-lg-10 col-md-12 col-sm-12">
                  <mini-statistics-card
                    :title="{ text: 'Total Pills Sent', value: values_lists.pills_resume.total_pillows }"
                    :icon="{
                      name: 'bookmark',
                      color: 'text-white',
                      background: 'dark',
                    }"
                  />
                </div>
                <div class="col-lg-10 col-md-12 col-sm-12">
                  <mini-statistics-card
                    :title="{ text: 'Activities Done', value: values_lists.pills_resume.total_pillows_answered }"
                    :icon="{
                      name: 'leaderboard',
                      color: 'text-white',
                      background: 'info',
                    }"
                  />
                </div>
                <div class="col-lg-10 col-md-12 col-sm-12">
                  <mini-statistics-card
                    :title="{ text: 'Activities Done / Total Pills Sent', value: percentAnswered }"
                    :icon="{
                      name: 'person',
                      color: 'text-white',
                      background: 'success',
                    }"
                  />
                </div>
              </div>

              <div class="d-flex justify-content-center align-items-start col-lg-8 col-md-12 col-sm-12 gap-2">
                <chart-holder-card class="col-lg-6 col-md-12 col-sm-12" color="dark" title="Pills Sent" update="Updated">
                  <reports-bar-chart
                    id="chart-pills-sent"
                    :chart="{
                      labels: labels_chart,
                      datasets: {
                        label: 'Pill sents',
                        data: data_sents_chart,
                      },
                    }"
                  />
                </chart-holder-card>

                <chart-holder-card class="col-lg-6 col-md-12 col-sm-12" color="dark" title="Pills Engagement" update="Updated">
                  <reports-bar-chart 
                    id="chart-pills-engagement"
                    :chart="{
                      labels: labels_chart,
                      datasets: {
                        label: 'Percent',
                        data: data_engagement_chart,
                      },
                    }"
                  />
                </chart-holder-card>
                
              </div>
            </div>
          </div>
          
          <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4 px-2">
            <list-pillow
              title="Pills Performance"
              description=""
              :headers="[
                'CONTENT PILL NAME',
                'Status',
                'Students',
                'Activity Done',
                'Activity Done / Students',
                'Created Date',
                'Sents Date',
                'More Infos'
              ]"
              :values_list="this.values_lists['pillow_informations']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import {http, http_file, url_from_app_user} from "../http/index";
import Loading from "@/views/components/Loading.vue";
import ListPillow from "./components/ListPillow.vue";
import lysLogo from "@/assets/img/icon-lys.png";
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";


export default {
  name: "PillowContents",

  components : {
    MaterialButton,
    ListPillow,
    MiniStatisticsCard,
    ChartHolderCard,
    Loading,
    ReportsBarChart,
  },

  computed :  {     
    camposPreenchidos() {
      if(this.project !=  false && this.course != false){
       return true;
      }
    } 
  },

  data() {
    return {
      lysLogo,
      values_lists: [],
      loading: true,
      percentAnswered: 0,
      ordened_pills: [],
      labels_chart: [],
      data_engagement_chart: [],
      data_sents_chart: [],
    };
  },

  mounted() {
    this.getValuesList();
  },

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
      
    },

    async getValuesList() {
      var payload = {
        token_user: localStorage.getItem("token"),          
      };

      http.post("/api/v1/dashboard/pills-informations/", payload)
      .then((response) => {
        this.values_lists = response.data

        let percentCalcutated = ((parseInt(response.data.pills_resume.total_pillows_answered) / parseInt(response.data.pills_resume.total_pillows)) * 100).toFixed(2)

        if (percentCalcutated != '' && percentCalcutated !== null && percentCalcutated !== 'undefined' && !isNaN(percentCalcutated)) {
          this.percentAnswered = percentCalcutated.toString() + '%'
        } else {
          this.percentAnswered = '0.00%'
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
        this.lastFourPills();
      })      
    },

    lastFourPills() {
      this.ordened_pills = this.values_lists.pillow_informations.sort(function(a,b) {
        return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
      });

      if (this.ordened_pills[0] !== undefined) {
        let first_pill = this.ordened_pills[0];
        
        let first_engagement = (parseInt(first_pill.count_answered) / parseInt(first_pill.total_registrad)) * 100;
        let first_data_sents = parseInt(first_pill.count_answered) + parseInt(first_pill.count_sent) + parseInt(first_pill.count_clicked);

        this.labels_chart.push(this.formatDate(first_pill.created, false) + ` #` + first_pill.id);
        this.data_engagement_chart.push(first_engagement);
        this.data_sents_chart.push(first_data_sents);
      }

      if (this.ordened_pills[1] !== undefined) {
        let second_pill = this.ordened_pills[1];
        
        let second_engagement = (parseInt(second_pill.count_answered) / parseInt(second_pill.total_registrad)) * 100;
        let second_data_sents = parseInt(second_pill.count_answered) + parseInt(second_pill.count_sent) + parseInt(second_pill.count_clicked);

        this.labels_chart.push(this.formatDate(second_pill.created, false) + ` #` + second_pill.id);
        this.data_engagement_chart.push(second_engagement);
        this.data_sents_chart.push(second_data_sents);
      }

      if (this.ordened_pills[2] !== undefined) {
        let third_pill = this.ordened_pills[2];
        
        let third_engagement = (parseInt(third_pill.count_answered) / parseInt(third_pill.total_registrad)) * 100;
        let third_data_sents = parseInt(third_pill.count_answered) + parseInt(third_pill.count_sent) + parseInt(third_pill.count_clicked);

        this.labels_chart.push(this.formatDate(third_pill.created, false) + ` #` + third_pill.id);
        this.data_engagement_chart.push(third_engagement);
        this.data_sents_chart.push(third_data_sents);
      }

      if (this.ordened_pills[3] !== undefined) {
        let fourth_pill = this.ordened_pills[3];
        
        let fourth_engagement = (parseInt(fourth_pill.count_answered) / parseInt(fourth_pill.total_registrad)) * 100;
        let fourth_data_sents = parseInt(fourth_pill.count_answered) + parseInt(fourth_pill.count_sent) + parseInt(fourth_pill.count_clicked);

        this.labels_chart.push(this.formatDate(fourth_pill.created, false) + ` #` + fourth_pill.id);
        this.data_engagement_chart.push(fourth_engagement);
        this.data_sents_chart.push(fourth_data_sents);
      }
    }
  }
}
</script>

<style scoped>
</style>