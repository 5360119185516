<template>
  <div class="container-fluid py-4">    
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
            <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
              <h6 class="col text-white text-capitalize ps-3">Push Batches Information</h6>
            </div>            

            <Loading message="Loading project push batches" :loading = "loading"/>

            <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4">
              <div class="table-responsive align-items-center mb-0" style = "margin: 0px 20px;">
                <table class="table-responsive table table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-left px-0">Name</th>
                            <th class="text-center">Sender</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Created by</th>
                            <th class="text-center">Project</th>
                            <th class="text-center">Total</th>
                            <th class="text-center">Processed</th>
                            <th class="text-center">Failures</th>
                            <th class="text-center">Modified</th>
                            <th class="text-center">Archive</th>
                            <th class="text-center">Visualize</th>
                        </tr>
                    </thead>

                    <tbody>
                      <tr v-if="Array.isArray(allPushBatches) && allPushBatches.length > 0" v-for="data in allPushBatches" :key="data.id">
                        <td class="align-middle"  wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">{{ data.identifier }}</td>
                        <td class="text-center align-middle">{{ data.sender_phone_number }}</td>
                        <td class="fs-4 text-center align-middle">
                          <i v-if="data.status == 'FINISHED'" class="bi bi-check-circle-fill text-success"></i>
                          <i v-else class="bi bi-x-circle-fill text-danger"></i>
                        </td>
                        <td class="text-center align-middle"  wrap="wrap" style="white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">{{ data.created_by_email }}</td>
                        <td class="text-center align-middle">{{ data.project_name }}</td>
                        <td class="text-center align-middle">{{ data.count_lines }}</td>
                        <td class="text-center align-middle">{{ data.processed }}</td>
                        <td class="text-center align-middle">{{ data.failed }}</td>
                        <td class="text-center align-middle">{{ formatDate(data.modified) }}</td>
                        <td class="text-center align-middle fs-4">
                          <i v-if="data.file_url != null" class="bi bi-filetype-csv text-success button-action cursor-click" @click="downloadFile(data.file_url)"></i>
                          <i v-else class="bi bi-x-circle-fill text-danger"></i>
                        </td>
                        <td class="text-center align-middle fs-4">
                          <router-link
                            :to="{ name: 'PushBatchView', params: { id: data.id } }"
                          >
                            <i class="bi bi-eye-fill text-info button-action"></i>
                          </router-link>                          
                        </td>
                      </tr>
                      <tr v-else>
                        <td class="text-center" colspan="11">
                          <span class="text-lg text-muted">Content not found</span>
                        </td>
                      </tr>
                    </tbody>
                </table>    
              </div>

              <div class="row mt-3" v-if="Array.isArray(allPushBatches) && allPushBatches.length > 0">
                <nav aria-label="...">
                  <ul class="pagination justify-content-center align-items-center gap-3">
                    <li v-if="this.currentPage > 1" class="page-item">
                      <i class="bi bi-caret-left-fill page-link fs-6" @click="previousPage(this.currentPage)"></i>
                    </li>

                    <li class="page-item fs-5 disabled">{{ this.currentPage }} of {{ this.totalPages }}</li>

                    <li v-if="this.currentPage < this.totalPages" class="page-item">
                      <i class="bi bi-caret-right-fill page-link fs-6" @click="nextPage(this.currentPage)"></i>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {http, http_file, url_from_app_user} from "../http/index";
import lysLogo from "@/assets/img/icon-lys.png";
import Loading from "@/views/components/Loading.vue";

export default {
  name: "PushBatchesInfo",

  components : {
    Loading
  },

  data() {
    return {
      lysLogo,
      allPushBatches: [],
      loading: true,
      project: null,
      currentPage: 1,
      totalPages: null,
    };
  },

  mounted() {
    this.getAllPushBatches();
  },

  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      
    },

    async getAllPushBatches() {
      var payload = {
        token: localStorage.getItem("token"),
        current_page: this.currentPage,
      };

      this.loading = true;

      http.post("/api/v1/dashboard/info_push_batchs/", payload)
      .then((response) => {
        this.allPushBatches = response.data.data;
        this.totalPages = response.data.total_pages;

        if (this.project) {
          this.allPushBatches = this.allPushBatches.filter((batch) => {
            return batch.project_name === this.project;
          });
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false;
      })
    },

    downloadFile: function (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },

    previousPage(actual_page) {
      let new_currentPage = actual_page - 1;
      this.currentPage = new_currentPage;

      this.getAllPushBatches();
    },

    nextPage(actual_page) {
      let new_currentPage = actual_page + 1;
      this.currentPage = new_currentPage;

      this.getAllPushBatches();
    },
  }
}
</script>

<style scoped>
  .cursor-click {
    cursor: pointer;
  }
</style>