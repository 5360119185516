<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://www.lys.academy/site/wp-content/uploads/2024/09/Blog-3-Agosto.png');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto" >
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-custom shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Lys Academy
                </h4>
                <div class="row mt-3">
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link me-auto" target="_blank" href="https://www.facebook.com/lys.academy">
                      <i class="fab fa-facebook text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link me-auto" target="_blank" href="https://www.instagram.com/lys.academy/">
                      <i class="fab fa-instagram text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link me-auto" target="_blank" href="https://www.linkedin.com/company/lysacademy">
                      <i class="fab fa-linkedin text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link me-auto" target="_blank" href="https://www.youtube.com/channel/UClFMYUd8_Nt28Oyz0KswFtg">
                      <i class="fab fa-youtube text-white text-lg"></i>
                    </a>
                  </div>                
                </div>
              </div>
            </div>
            <div  class="card-body" @keyup.enter="put_login">

              <div v-if = "errorLogin == true" class="error">Invalid data, please try again </div>
              
              <div class="text-start mt-3" data-v-6a47f4d6="">
                <div class="mb-3" data-v-6a47f4d6="">
                  <div class="input-group input-group-outline null" data-v-6a47f4d6="">
                    <label class="form-label">Email</label>
                    <input v-model="email" id="email" type="email" class="form-control form-control-default" name="email" placeholder="" isrequired="false">
                  </div>
                </div>
                <div data-v-6a47f4d6="">
                    <div class="input-group input-group-outline null" data-v-6a47f4d6="">
                      <label class="form-label">Password</label>
                      <input v-model="password" id="password" type="password" class="form-control form-control-default" name="password" placeholder="" isrequired="false">
                    </div>
                </div>  
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                >
                  Forgot password ?
                </router-link> 

                <a class="text-center" data-v-6a47f4d6="">
                  <button @click="put_login" class="btn mb-0 bg-gradient-custom btn-md w-100 null my-4 mb-2 text-white" data-v-6a47f4d6="">Login </button>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              <a
                href="https://www.lys.academy/br/"
                class="font-weight-bold text-white"
                target="_blank"
                >© 2021 LYS ACADEMY Ltda. CNPJ: 31.454.568/0001-06 | Política De Privacidade</a>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://www.lys.academy/br/sobre-a-lys/"
                  class="nav-link text-white"
                  target="_blank"
                  >Lys Academy</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.lys.academy/br/solucoes/metodologia-exclusiva/"
                  class="nav-link text-white"
                  target="_blank"
                  >Conheça mais</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.lys.academy/br/blog/"
                  class="nav-link text-white"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://www.lys.academy/br/contato/"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Contato</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import {http} from "../http/index";
import { VueElement } from "vue";
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "sign-in",
  components: {
    MaterialInput,
    MaterialButton,
  },

  mounted() {
    setMaterialInput();
  },

  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },

  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },

  data() {
    return {
      email: null,
      password: null,
      token: null,
      errorLogin : false,
      data_request_login: null,
      first_name: null,
      last_name: null,
      load_login: null,
    };
  },
  
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async put_login() {

      this.errorLogin = false;
      this.load_login = true;
      this.load_login = true;

      var payload = {
        email: this.email,
        password: this.password,
      };
      
      http.post('/api/v1/dashboard/login_client/', payload)
        .then((response) => {         
          this.data_request_login = response.data;
          this.token = this.data_request_login.key;
          this.mac_token = this.data_request_login.hardware_key;
          this.email = this.data_request_login.email;
          this.first_name = this.data_request_login.first_name;
          this.last_name = this.data_request_login.last_name;
          localStorage.setItem("token", this.token);
          localStorage.setItem("mac_token", this.mac_token);
          localStorage.setItem("email", this.email);
          localStorage.setItem("first_name", this.first_name);
          localStorage.setItem("last_name", this.last_name);
          
          this.$router.push('/')
        })
        .catch((error) => {
          this.errorLogin = true;
          this.load_login = false          
        })
        .finally(() => {
          
        });

        
    },
   
  },
};
</script>

<style>
.bg-gradient-custom {
  background: linear-gradient(to right ,#995beb,#af03ff);
  /* Substitua as cores acima pelas cores desejadas */
}

.error{
  color: red;
  font-weight: 500;
}



.text-start {
    text-align: left !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*, :after, :before {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
    unicode-bidi: isolate;
}
.card .card-body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    padding: 1.5rem;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: .75rem;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -0.5);
    margin-left: calc(var(--bs-gutter-x)* -0.5);
}
body {
    font-weight: 400;
    line-height: 1.6;
}
body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
:root {
    --bs-blue: #63b3ed;
    --bs-indigo: #596cff;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #f56565;
    --bs-orange: #fd7e14;
    --bs-yellow: #fbd38d;
    --bs-green: #81e6d9;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #f0f2f5;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #e91e63;
    --bs-secondary: #7b809a;
    --bs-success: #4caf50;
    --bs-info: #1a73e8;
    --bs-warning: #fb8c00;
    --bs-danger: #f44335;
    --bs-light: #f0f2f5;
    --bs-dark: #344767;
    --bs-white: #fff;
    --bs-primary-rgb: 233, 30, 99;
    --bs-secondary-rgb: 123, 128, 154;
    --bs-success-rgb: 76, 175, 80;
    --bs-info-rgb: 26, 115, 232;
    --bs-warning-rgb: 251, 140, 0;
    --bs-danger-rgb: 244, 67, 53;
    --bs-light-rgb: 240, 242, 245;
    --bs-dark-rgb: 52, 71, 103;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 123, 128, 154;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #7b809a;

}


</style>