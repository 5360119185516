<template>
  <div>
    <sidenav
      :custom_class="color"
      :class="[isRTL ? 'fixed-end' : 'fixed-start']"
      v-if="showSidenav"
    />
    <main
      class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
    >
      <!-- nav -->
      <div>
        <navbar
          :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
          :color="isAbsolute ? 'text-white opacity-8' : ''"
          :minNav="navbarMinimize"
          v-if="showNavbar"
        />
        <router-view />
        
        <app-footer v-show="showFooter" />
        

        <!-- TODO: Sistema de suporte -->
        
        <!-- <configurator
          :toggle="toggleConfigurator"
          :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
        /> -->
       
      </div>
    </main>
  </div>
</template>

<script>

import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import SignInVue from "./views/SignIn.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import RecoveringPassword from "./views/RecoveringPassword.vue";
import { http } from "./http";

export default {
  name: "App",
  components: {
    SignInVue,
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
    ForgotPassword,
    RecoveringPassword,
  },
  data() {
    return {
      login: true,
      name_user: null,
      email_user: null,
      token: false,
    };
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    
    confirmToken() {
        const payload = {
          token: localStorage.getItem("token"),
          mac_token: localStorage.getItem("mac_token"),
        };
        
        http.post("/api/v1/dashboard/confirm_token_client/", payload)
          .then(response => {
            this.login = response.status === 200;            
          })
          .catch(error => {
            localStorage.clear();
            const path = window.location.pathname.split('/')[1];
            if (this.$route.path !== '/forgot-password' && (`/${path}`)!== '/recovering-password') {
              this.$router.push('/login');
            }
          });
      },

  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },

  updated() {
    const token = localStorage.getItem("token");
    const path = window.location.pathname.split('/')[1];
    const isPasswordRecoveryRoute = this.$route.path === '/forgot-password' && (`/${path}`)!== '/recovering-password';
    if (token && !isPasswordRecoveryRoute) {
      this.confirmToken();
    }
  },

  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    let router = this.$route.path
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    };
    const path = window.location.pathname.split('/')[1];
    
    if (router !== '/forgot-password' && (`/${path}`)!== '/recovering-password') {
      if (localStorage.getItem("token")) {
        this.confirmToken();
      } else {
        this.$router.push('/login');
      }
    }
  },

};
</script>
<style>
.page-link {
    cursor: pointer;
}
</style>
